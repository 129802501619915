import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const HeroSection = () => {
  return (
    <section 
      className="technoration-bg py-5"
    >
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <h1 className="display-4 fw-bold mb-4">
              Transform Your Business with <span style={{ color: '#F9A825' }}>Smart Solutions</span>
            </h1>
            <p className="lead mb-4">
              Empowering enterprises with scalable AI-driven software solutions for endless generation growth.
            </p>
            <Button variant="light" size="lg">
              Start Free Trial
            </Button>
          </Col>
          <Col lg={6}>
            <img 
              src="https://images.unsplash.com/photo-1552581234-26160f608093?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" 
              alt="Platform Preview" 
              className="img-fluid global-br"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;