import React from 'react';
import { Container, Row, Col, Card, ListGroup, Button } from 'react-bootstrap';

const PricingSection = () => {
  const plans = [
    {
      name: "Starter",
      price: "299",
      features: ["Basic CRM", "5 Users", "10GB Storage", "Email Support"]
    },
    {
      name: "Professional",
      price: "599",
      features: ["Advanced CRM", "Unlimited Users", "100GB Storage", "Priority Support"]
    },
    {
      name: "Enterprise",
      price: "Custom",
      features: ["Custom Solutions", "Dedicated Support", "On-premise Deployment", "SLA 99.9%"]
    }
  ];

  return (
    <section className="py-5 bg-light">
      <Container>
        <h2 className="text-center mb-5">Simple, Transparent Pricing</h2>
        <Row className="g-4">
          {plans.map((plan, index) => (
            <Col lg={4} key={index}>
              <Card className="h-100">
                <Card.Body className="d-flex flex-column">
                  <Card.Title>{plan.name}</Card.Title>
                  <div className="display-4 mb-3">
                    ${plan.price}{typeof plan.price === 'number' && <small>/mo</small>}
                  </div>
                  <ListGroup variant="flush" className="mb-3">
                    {plan.features.map((feature, i) => (
                      <ListGroup.Item key={i}>{feature}</ListGroup.Item>
                    ))}
                  </ListGroup>
                  <Button variant="primary" className="mt-auto technoration-bg">Get Started</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default PricingSection;