import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    location: {}
  });

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(position => {
      setFormData(prev => ({
        ...prev,
        location: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      }));
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post('/api/contact', formData);
  };

  return (
    <Container className="py-5">
      <Row>
        <Col md={6}>
          <h2>Get in Touch</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Your Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Your Email</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Your Message</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter your message" required />
            </Form.Group>
            <Button onClick={getLocation}>Get Location</Button>
            <Button type="submit" className="ms-2">Send Message</Button>
          </Form>
        </Col>
        <Col md={6}>
          <div className="map-container">
            <iframe 
              title="Office Location"
              width="100%" 
              height="400"
              src={`https://maps.google.com/maps?q=${encodeURIComponent("House No. 08/12 (1st Floor), Road No.4, Mirpur 1216, Dhaka, Bangladesh")}&output=embed`}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactPage;