import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: {
        welcome: "Welcome to Technoration",
        about: "About Us",
        contact: "Contact Us"
      }
    },
    bn: {
      translations: {
        welcome: "টেকনোরেশনে স্বাগতম",
        about: "আমাদের সম্পর্কে",
        contact: "যোগাযোগ করুন"
      }
    }
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false }
});

export default i18n;