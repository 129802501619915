import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const FeaturesSection = () => {
  const features = [
    {
      title: "AI-Powered Solutions",
      description: "Leverage cutting-edge artificial intelligence for business automation",
      icon: "🤖"
    },
    {
      title: "24/7 Support",
      description: "Round-the-clock technical support with guaranteed SLAs",
      icon: "🛡️"
    },
    {
      title: "Scalable Architecture",
      description: "Cloud-native solutions that grow with your business",
      icon: "📈"
    }
  ];

  return (
    <section className="py-5">
      <Container>
        <h2 className="text-center mb-5">Why Choose Technoration?</h2>
        <Row>
          {features.map((feature, index) => (
            <Col md={4} key={index} className="mb-4">
              <div className="feature-card p-4">
                <div className="feature-icon display-4 mb-3">{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default FeaturesSection;