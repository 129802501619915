import React, { useContext } from 'react';
import { Navbar, Nav, Container, Button, NavDropdown } from 'react-bootstrap';
import { ThemeContext } from '../context/ThemeContext';
import { FiSearch, FiHome, FiInfo, FiMail, FiUsers, FiBriefcase, FiCalendar } from 'react-icons/fi';
import SearchModal from './SearchModal';
import LanguageSwitcher from './LanguageSwitcher';
import ThemeToggle from './ThemeToggle';

const Header = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <Navbar bg={theme === 'light' ? 'light' : 'dark'} variant={theme} expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img 
            src="/logo.png" 
            height="40" 
            alt="Technoration Logo" 
            className="me-2"
          />
          <span className="fw-bold" style={{ color: theme === 'light' ? '#2A4E8C' : 'white' }}>
            technoration
          </span>
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="main-nav" />
        
        <Navbar.Collapse id="main-nav">
          <Nav className="me-auto">
            <Nav.Link href="/" className="d-flex align-items-center">
              <FiHome className="me-2" /> Home
            </Nav.Link>
            <NavDropdown title={<span><FiInfo className="me-2" />About</span>} id="about-dropdown">
              <NavDropdown.Item href="/about">About Us</NavDropdown.Item>
              <NavDropdown.Item href="/team">Our Team</NavDropdown.Item>
            </NavDropdown>
            
            <NavDropdown title={<span><FiBriefcase className="me-2" />Services</span>} id="services-dropdown">
              <NavDropdown.Item href="/web-development">Web Development</NavDropdown.Item>
              <NavDropdown.Item href="/mobile-development">Mobile Development</NavDropdown.Item>
              <NavDropdown.Item href="/cloud-solutions">Cloud Solutions</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/contact" className="d-flex align-items-center">
              <FiMail className="me-2" /> Contact
            </Nav.Link>
          </Nav>
          
          <div className="d-flex align-items-center">
            <SearchModal />
            <LanguageSwitcher />
            <ThemeToggle />
            <Button variant="primary" className="ms-3 technoration-bg">
              <FiCalendar className="me-2" /> Schedule Demo
            </Button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;