import React from 'react';
import { Container } from 'react-bootstrap';

const Terms = () => {
  return (
    <Container className="py-5">
      <h1>Terms and Conditions</h1>
      <p>Detailed terms and conditions content goes here...</p>
    </Container>
  );
};

export default Terms;