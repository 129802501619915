import React, { useEffect, useState } from "react";
import "../assets/styles/pageloader.css"; // We'll create this CSS file next

const PageLoader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Detect when the DOM is fully loaded
    const handleLoad = () => {
      setLoading(false); // Hide the loader
    };

    // Add event listener for when the DOM is fully loaded
    window.addEventListener('load', handleLoad);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  if (!loading) return null;

  return (
    <div className="page-loader">
      <div className="loader-spinner"></div>
    </div>
  );
};

export default PageLoader;