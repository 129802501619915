import React from 'react';
import { Container, Carousel } from 'react-bootstrap';

const TestimonialsSection = () => {
  const testimonials = [
    {
      name: "John Doe",
      position: "CEO, TechCorp",
      text: "Technoration transformed our business operations with their innovative solutions.",
      image: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80"
    },
    {
      name: "Jane Smith",
      position: "CTO, Innovate Inc.",
      text: "Their AI-powered CRM system has been a game-changer for our sales team.",
      image: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80"
    }
  ];

  return (
    <section className="py-5">
      <Container>
        <h2 className="text-center mb-5">What Our Clients Say</h2>
        <Carousel>
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index}>
              <div className="testimonial-card text-center p-4">
                <img 
                  src={testimonial.image} 
                  alt={testimonial.name} 
                  className="rounded-circle mb-3"
                  width="100"
                  height="100"
                />
                <h3>{testimonial.name}</h3>
                <p className="text-muted">{testimonial.position}</p>
                <p className="lead">{testimonial.text}</p>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </section>
  );
};

export default TestimonialsSection;