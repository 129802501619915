import React from 'react';
import { Container } from 'react-bootstrap';

const Cookies = () => {
  return (
    <Container className="py-5">
      <h1>Cookie Policy</h1>
      <p>Detailed cookie policy content goes here...</p>
    </Container>
  );
};

export default Cookies;