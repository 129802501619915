import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { ThemeContext } from '../context/ThemeContext';
import { FaMoon, FaSun } from 'react-icons/fa';

const ThemeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <Button variant="link" onClick={toggleTheme}>
      {theme === 'light' ? <FaMoon size={20} /> : <FaSun size={20} />}
    </Button>
  );
};

export default ThemeToggle;