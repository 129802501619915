import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const Hit = ({ hit }) => (
  <div>
    <h4>{hit.title}</h4>
    <p>{hit.description}</p>
  </div>
);

const SearchModal = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button variant="link" onClick={() => setShow(true)}>
        <FiSearch size={20} />
      </Button>

      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Search Knowledge Base</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InstantSearch searchClient={searchClient} indexName="technoration">
            <SearchBox />
            <Hits hitComponent={Hit} />
          </InstantSearch>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SearchModal;