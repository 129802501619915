import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaLinkedin, FaTwitter, FaInstagram, FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="technoration-bg text-light py-5">
      <Container>
        <Row>
          <Col md={4} className="mb-4">
            <h5 className="mb-3">Technoration Ltd.</h5>
            <p className="text-dark">Smart Solutions, Endless Generation</p>
            <div className="social-icons">
              <a href="#" className="text-light me-3"><FaFacebook size={20} /></a>
              <a href="#" className="text-light me-3"><FaLinkedin size={20} /></a>
              <a href="#" className="text-light me-3"><FaTwitter size={20} /></a>
              <a href="#" className="text-light"><FaInstagram size={20} /></a>
            </div>
          </Col>

          <Col md={2} className="mb-4">
            <h6 className="mb-3">Quick Links</h6>
            <ul className="list-unstyled">
              <li><a href="/" className="text-dark text-decoration-none"><FaMapMarkerAlt className="me-2" />Home</a></li>
              <li><a href="/about" className="text-dark text-decoration-none"><FaMapMarkerAlt className="me-2" />About Us</a></li>
              <li><a href="/services" className="text-dark text-decoration-none"><FaMapMarkerAlt className="me-2" />Services</a></li>
              <li><a href="/contact" className="text-dark text-decoration-none"><FaMapMarkerAlt className="me-2" />Contact</a></li>
            </ul>
          </Col>

          <Col md={3} className="mb-4">
            <h6 className="mb-3">Contact Info</h6>
            <ul className="list-unstyled">
              <li className="text-dark mb-2">
                <FaMapMarkerAlt className="me-2" />
                House No. 08/12 (1st Floor), Road No.4, Mirpur 1216, Dhaka, Bangladesh
              </li>
              <li className="text-dark mb-2">
                <FaPhone className="me-2" />
                +8801722696769
              </li>
              <li className="text-dark">
                <FaEnvelope className="me-2" />
                info@technoration.com
              </li>
            </ul>
          </Col>

          <Col md={3}>
            <h6 className="mb-3">Newsletter</h6>
            <p className="text-dark">Subscribe to our newsletter for the latest updates.</p>
            <form>
              <div className="input-group">
                <input type="email" className="form-control" placeholder="Enter your email" />
                <button className="btn btn-primary technoration-bg" type="submit">Subscribe</button>
              </div>
            </form>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="text-center">
            <p className="text-dark mb-0">&copy; {new Date().getFullYear()} Technoration Ltd. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;